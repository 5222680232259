import indexVideoPlayer from "../javascripts/indexVideoPlayer";
import setupCaptions from '../javascripts/videoCaptionsScroller';

// document.addEventListener('DOMContentLoaded', () => {
//     const videoElement = document.getElementById('videoElement');
//     const captionsContainer = document.getElementById('captionsContainer');
//     const vttUrl = 'your-caption-file.vtt';
//
//     if (videoElement && captionsContainer) {
//         setupCaptions(videoElement, captionsContainer, vttUrl);
//     }
// });
window.current_video_player = null;
window.current_video_player_original_el = null;

window.indexPlayer = {
    current_video_player: null,
    current_video_player_original_el: null,
    current_playing_video_id: null,
}
document.addEventListener('DOMContentLoaded', () => {

    document.querySelectorAll('.click_to_play_thumb').forEach(function(el){
        el.addEventListener('click', function(e){

            let pData = window.indexPlayer
            if(pData.current_video_player) {
                pData.current_video_player.parentNode.replaceChild(pData.current_video_player_original_el, pData.current_video_player);
                let vid = pData.current_playing_video_id;
                let video_captions_card = document.getElementById(`captions_video_${vid}`);
                video_captions_card.classList.remove('show')

                let video_summary_card = document.getElementById(`summary_video_${vid}`);
                video_summary_card.classList.add('show')

                window.indexPlayer.current_video_player = null;
                window.indexPlayer.current_video_player_original_el = null;
                window.indexPlayer.current_playing_video_id = null;
            }

            let video_id = e.target.dataset.id;
            let video_vtt_url = e.target.dataset.vtturl;
            let video_url = e.target.dataset.playlisturl;

            let video_thumb = document.getElementById(`video_${video_id}_thumbnail`);

            let video_captions_card = document.getElementById(`captions_video_${video_id}`);
            video_captions_card.classList.add('show')

            let video_summary_card = document.getElementById(`summary_video_${video_id}`);
            video_summary_card.classList.remove('show')

            window.video_captions_card = video_captions_card;
            let video_captions_container = video_captions_card.querySelector('.captions-container');

            let video_player = document.createElement('video');

            video_player.src = "";
            video_player.controls = true;
            video_player.autoplay = true;
            video_player.classList.add('img-fluid');
            video_player.classList.add('click_to_play_player');
            video_player.dataset.videoId = video_id;
            video_player.poster = e.target.src;

            indexPlayer.current_video_player = video_player;
            indexPlayer.current_video_player_original_el = video_thumb;
            indexPlayer.current_playing_video_id = video_id;

            video_thumb.parentNode.replaceChild(video_player, video_thumb);

            indexVideoPlayer(video_player, video_url);
            setupCaptions(video_player, video_captions_container, video_vtt_url);
        });
    });


});


// when thumb clicked replace thumb with video player
// and load video
