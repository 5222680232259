
export async function setupCaptions(videoElement, captionsContainer, vttUrl) {
    async function fetchAndParseVTT(url) {

        captionsContainer.innerHTML = 'Loading transcript...';


        const response = await fetch(url);
        const text = await response.text();
        const lines = text.split('\n');
        const cues = [];

        let currentCue = null;


        for (const line of lines) {
            if (!currentCue && line.startsWith('WEBVTT')) {
                continue;
            }

            if (!currentCue && line.trim() === '') {
                continue;
            }

            if (!currentCue) {
                const [start, end] = line.split(' --> ').map((time) => {

                    // 00:00.000 --> 00:02.880
                    const [minutes, rest] = time.trim().split(':');

                    const [seconds, milliseconds] = rest.split('.');
                    return (
                        parseFloat(minutes) * 60 +
                        parseFloat(seconds) +
                        parseFloat(milliseconds) / 1000
                    );
                });

                currentCue = new VTTCue(start, end, '');
                continue;
            }

            if (line.trim() === '') {
                cues.push(currentCue);
                currentCue = null;
            } else {
                currentCue.text += (currentCue.text ? '\n' : '') + line;
            }
        }

        if (currentCue) {
            cues.push(currentCue);
        }

        return cues;
    }

    function scrollToCurrentLine(currentLine) {
        const containerHeight = captionsContainer.offsetHeight;
        const lineTop = currentLine.offsetTop;
        const lineMiddle = lineTop - containerHeight / 2 + currentLine.offsetHeight / 2;

        // Calculate the maximum scrollTop value to prevent scrolling beyond the bottom of the container
        const maxScrollTop = captionsContainer.scrollHeight - containerHeight;

        // Ensure the scrollTop value is between 0 and the maximum scrollTop value
        captionsContainer.scrollTop = Math.min(Math.max(lineMiddle, 0), maxScrollTop);
    }

    fetchAndParseVTT(vttUrl).then((cues) => {

        captionsContainer.innerHTML = '';

        cues.forEach((cue) => {
            const cue_index = cues.indexOf(cue);
            const line = document.createElement('div');
            line.textContent = cue_index + ": " + cue.text;
            line.className = 'caption-line';
            line.dataset.startTime = cue.startTime;

            line.addEventListener('click', () => {
                videoElement.currentTime = parseFloat(line.dataset.startTime);
                videoElement.play();
            });

            captionsContainer.appendChild(line);
            cue.element = line;
        });

        videoElement.ontimeupdate = () => {
            const currentTime = videoElement.currentTime;
            const matchingCue = cues.find(
                (cue) => cue.startTime <= currentTime && cue.endTime >= currentTime
            );

            captionsContainer.querySelectorAll('.caption-line.current-line').forEach((line) => {
                line.classList.remove('current-line');
            });

            if (matchingCue) {
                matchingCue.element.classList.add('current-line');
                scrollToCurrentLine(matchingCue.element);
            } else {

            }
        };
    });
}
export default setupCaptions;
