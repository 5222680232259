
const indexVideoPlayer = (video_el, video_src) => {


    function SetupCallbacks(hls) {


        hls.on(Hls.Events.ERROR, function (event, data) {
            var errorType = data.type;
            var errorDetails = data.details;
            var errorFatal = data.fatal;

            console.log("HLS Error: " + errorType, event, data)

        });

    }

    function VideoThumbClicked(video_el, video_src){
        if (Hls.isSupported()) {

            const config = {capLevelToPlayerSize: true, startLevel: -1}
            const hls = new Hls(config);
            window.hls = hls;
            SetupCallbacks(hls);

            hls.loadSource(video_src);
            hls.attachMedia(video_el);


        }else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.addEventListener('loadedmetadata', function() {
                video.src = video_src;
                video.mute();
                video.play();
            });
        }
    }

    console.log("indexVideoPlayer.js loaded", video_el, video_src)

    if(video_el && video_src) {
        VideoThumbClicked(video_el, video_src);
    }else {
        console.log("indexVideoPlayer.js: video_el or video_src not found")
    }

}
export default indexVideoPlayer;
